<template>
  <v-main>
    
    <router-view />

    <dashboard-core-footer />
  </v-main>
</template>

<script>
export default {
  name: "CoursesCoreView",
  
  components: {
    DashboardCoreFooter: () =>
      import("@/views/dashboard/components/core/Footer"),
  },
};
</script>
